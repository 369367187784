import dayjs from "dayjs";
import {
    CIVIL_STATUS,
    DIRECTED_BY,
    EMPLOYEE_STATUS,
    ORGANIZATION_LINK,
    SERVICES,
    USED_SERVICE_BEFORE
} from "../constants/enums";
import {GENDER, Parent} from "./patient";

class ContactRequest{
    public _id?: string = undefined;
    public confirmationNumber?: number;
    public patientId?: string = undefined;
    public createdOn?: Date = undefined;
    public transmittedOn?: Date = undefined;
    public archivedOn?: Date = undefined;
    public waiting?: boolean = undefined;
    public communications: ContactCommunication[] = [];
    public firstName?: string = undefined;
    public lastName?: string = undefined;
    public birthdate?: Date = undefined;
    public address?: string = undefined;
    public postalCode?: string = undefined;
    public email?: string = undefined;
    public phoneWork?: string = undefined;
    public phoneWorkExt?: string = undefined;
    public phoneHome?: string = undefined;
    public phoneCell?: string = undefined;
    public civilStatus?: CIVIL_STATUS = undefined;
    public civilStatusOriginal?: string = undefined;
    public usedServicePast?: USED_SERVICE_BEFORE = undefined;
    public usedServicePastOriginal?: string = undefined;
    public requestedService?: SERVICES = undefined;
    public requestedServiceOriginal?: string = undefined;
    public organization?: string = undefined;
    public organizationOriginal?: string = undefined;
    public organizationLink?: ORGANIZATION_LINK = undefined;
    public organizationLinkOriginal?: string = undefined;
    public employeeName?: string = undefined;
    public employeeStatus?: EMPLOYEE_STATUS = undefined;
    public employeeStatusOriginal?: string = undefined;
    public employmentTime?: string = undefined;
    public directedBy?: DIRECTED_BY = undefined;
    public directedByOriginal?: string = undefined;
    public comments?: string = undefined;
    public eventDate?: Date = undefined;
    public fileNumberIVACCSST?: string = undefined;
    public parent1?: Parent = undefined;
    public parent2?: Parent = undefined;
    public personalNote?: string = undefined;
    public ramqNo?: string = undefined;
    public ramqExpirationMonth?: number = undefined;
    public ramqExpirationYear?: number = undefined;
    public gender?: GENDER = undefined;

    static collection: string = "contactRequests";

    public constructor(init?:Partial<ContactRequest>) {
        if (init && init.createdOn) init.createdOn = dayjs(init.createdOn).isValid() ? dayjs(init.createdOn).toDate() : undefined;
        if (init && init.transmittedOn) init.transmittedOn = dayjs(init.transmittedOn).isValid() ? dayjs(init.transmittedOn).toDate() : undefined;
        if (init && init.archivedOn) init.archivedOn = dayjs(init.archivedOn).isValid() ? dayjs(init.archivedOn).toDate() : undefined;
        if (init && init.birthdate) init.birthdate = dayjs(init.birthdate).isValid() ? dayjs(init.birthdate).toDate() : undefined;
        Object.assign(this, init);
    }
}

export class ContactCommunication {
    public userId?: string = undefined;
    public date?: Date = undefined;
    public type?: string = undefined;
    public result?: string = undefined;

    public constructor(init?:Partial<ContactCommunication>) {
        Object.assign(this, init);
    }
}

export const COMMUNICATION_TYPE = {
    PHONE: 'PHONE',
    SMS: 'SMS',
    EMAIL: 'EMAIL',
    VOICE: 'VOICE',
    WEB: 'WEB',
}

export {ContactRequest};
