import {ILocal} from "../helpers/localization";

const local_fr:ILocal = {
    'test': 'test_fr',
    'Dashboard': 'Tableau de bord',
    'Requests': 'Demandes',
    'Meetings': 'Rencontres',
    'Accounts': 'Comptes',
    'Customers': 'Clients',
    'System logs': 'Registres système',
    'Received on': 'Reçu le',
    'First name': 'Prénom',
    'Last name': 'Nom de famille',
    'Organization': 'Organisation',
    'Email': 'Courriel',
    'Phone number': 'Téléphone',
    'Contact request': 'Demande de rencontre',
    'Contact requests': 'Demandes de rencontre',
    'Delete': 'Supprimer',
    'Save': 'Sauvegarder',
    'Transmit': 'Transmettre',
    'Delete this contact requests?': 'Supprimer cette demande de rencontre?',
    'Birthdate': 'Date de naissance',
    'Civil status': 'État civil',
    'Single': 'Célibataire',
    'Spouse': 'Conjoint(e) de fait',
    'Maried': 'Marié(e)',
    'Divorced': 'Divorcé(e)',
    'Widow(er)': 'Veuf(ve)',
    'Address': 'Adresse',
    'Postal code': 'Code postal',
    'Cell Phone': 'Cellulaire',
    'Phone at work': 'Tél. au travail',
    'Phone at home': 'Tél. à la maison',
    'Organization link': 'Lien avec l\'organisation',
    'Child': 'Enfant',
    'Employee': 'Employé(e)',
    'Employee name': 'Nom de l\'employé(e)',
    'Employee status': 'Status d\'employé(e)',
    'Employee time': 'À l\'emploi depuis',
    'Executive': 'Cadre',
    'Syndicated': 'Syndiqué',
    'Permanent': 'Permanent',
    'Part-time': 'Temps partiel',
    'TPO': 'TPO',
    'Temporary': 'Temporaire',
    'Contractual': 'Contractuel',
    'Retired': 'Retraité',
    'This action will archive this contact request for 10 days. After this period, it will be removed forever. Do you want to continue?': 'Cette action va archiver cette demande de rencontre pour 10 jours. Après cette période, elle sera supprimée pour toujours. Voulez-vous continuer?',
    'Requested service': 'Service demandé',
    'Employee Assistance Program (EAP)': 'Progamme d\'aide aux employés (PAE)',
    'Psychology/social work service CNESST, IVAC, Health Canada, Blue Cross, other': 'Service de psychologie/travail social CNESST, IVAC, Santé Canada, Croix Bleue, autre',
    'Private Psychology/Social Work Service': 'Service de psychologie/travail social privé',
    'Neuropsychology Service': 'Service de Neuropsychologie',
    'Private ABC Clinic Service': 'Service Clinique ABC en privé',
    'Mediation service, conflict resolution at work, psychological harassment and others in between': 'Service de médiation, résolution de conflit au travail, harcèlement psychologique et autres en entre',
    'Used service past': 'A déjà utilisé le service',
    'Yes': 'Oui',
    'No': 'Non',
    'Directed by': 'Dirigé par',
    'EAP Advertising': 'Publicité du PAE',
    'Doctor\'s recommendation': 'Recommendation du médecin',
    'IVAC': 'IVAC',
    'Suggestion from a colleague/friend': 'Suggestion d\'un confrère/ami',
    'Employer recommendation': 'Recommendation de l\'employeur',
    'SAAQ': 'SAAQ',
    'Suggestion from the family': 'Suggestion de la famille',
    'Union recommendation': 'Recommandation syndicale',
    'Quebec government': 'Gouvernement Québec',
    'Already viewed': 'Déjà consulté',
    'CNESST': 'CNESST',
    'Health Canada': 'Santé Canada',
    'Comments': 'Commentaires',
    'Communications': 'Communications',
    'Add communication': 'Ajouter une communication',
    'Request received': 'Demande reçue',
    "Menu": "Menu",
    "Patients": "Patients",
    "Name": "Nom",
    "Status": "Statut",
    "Contracts": "Contrats",
    "Account": "Compte",
    "Opened on": "Ouvert le",
    "Closed on": "Fermé le",
    "Employee's meets": "Rencontres employé(e)",
    "Spouse's meets": "Rencontres conjoint(e)",
    "Children's meets": "Rencontres enfants",
    "Deactivate this account?": "Désactiver ce compte?",
    "Are you sure you want to deactivate this account?": "Êtes-vous sûr de vouloir désactiver ce compte?",
    "Are you sure you want to deactivate this patient?": "Êtes-vous sûr de vouloir désactiver ce patient?",
    "Are you sure you want to reactivate this patient?": "Êtes-vous sûr de vouloir résactiver ce patient?",
    "Change theme": "Changer le thème",
    "Profile": "Profil",
    "Log out": "Se déconnecter",
    "Active": "Actif",
    "Edit": "Modifier",
    "Notifications": "Notifications",
    "Deactivate": "Désactiver",
    "Reactivate": "Réactiver",
    "Patient": "Patient",
    "Communicate by": "Communiqué par",
    "Communication's result": "Résultat de la communication",
    "Cancel": "Annuler",
    "Successfully saved": "Sauvegardé avec succès",
    "An error occurred, please try again": "Une erreur est survenue, s.v.p. ré-essayer encore",
    "Meeting follow up": "Suivi de rencontre",
    "Duration": "Durée",
    "Created": "Créé",
    "Completed": "Complété",
    "Missed": "Manqué",
    "Reported": "Reporté",
    "Canceled": "Annulé",
    "date": "date",
    "from": "de",
    "to": "à",
    "No patient found": "Aucun patient trouvé",
    "Professional": "Professionnel(le)",
    "Note": "Note",
    "Configurations": "Configurations",
    "Meeting": "Rencontre",
    "Start typing to search": "Commencez à écrire pour rechercher",
    "Unsaved changes": "Modifications non enregistrées",
    "All unsaved changes will be lost. Do you want to continue?": "Toutes les modifications non enregistrées seront perdues. Voulez-vous continuer?",
    "Link Patient": "Lier le patient",
    "One or more patient correspond to the contact request. Please select the patient to set the meeting or click on \"Create new patient\"": "Un ou plusieurs patients correspondent à la demande de contact. Veuillez sélectionner le patient pour définir la réunion ou cliquez sur \"Créer un nouveau patient\"",
    "Create new patient": "Créer un nouveau patient",
    "All": "Tous",
    "None": "Aucun",
    "Follow up": "Suivi",
    "Confirm presence": "Confirmer la présence",
    "Cancel the meeting": "Annuler la rencontre",
    "Are-you sure you want to cancel this meeting?": "Êtes-vous sûr de vouloir annuler cette rencontre?",
    "Patient history": "Historique du patient",
    "There is no previous meeting for this patient": "Il n'y a aucune rencontre précédente pour ce patient",
    "Meeting duration": "Durée de la rencontre",
    "Follow up completed": "Suivi complété",
    "Close": "Fermer",
    "No notifications": "Aucune notification",
    "On the patient's account": "Au compte du patient",
    "This meeting is at charge of the patient.": "Cette rencontre est au compte du patient.",
    "Please ask for payment before the meeting.": "S.V.P. demandez le paiement avant la rencontre.",
    "EMPLOYEE": "Employé(e)",
    "SPOUSE": "Conjoint(e)",
    "CHILD": "Enfant",
    "There is no remaining meetings for this patient.": "Il n'y a plus de rencontres restantes pour ce patient.",
    "Please change the meeting to be at charge of the patient.": "S.V.P. changer la rencontre pour être à la charge du patient.",
    "Remaining meetings": "Rencontres restantes",
    "Previous meetings": "Rencontres précédentes",
    "Presence confirmed": "Présence confirmée",
    "Start": "Début",
    "Stop": "Fin",
    "Print": "Imprimer",
    "Meeting Follow up": "Suivi de rencontre",
    "SINGLE": "Célibataire",
    "MARIED": "Marié (e)",
    "DIVORCED": "Divorcé (e)",
    "WIDOWER": "Veuf (ve)",
    "EXECUTIVE": "Cadre",
    "SYNDICATED": "Syndiqué",
    "PERMANENT": "Permanant",
    "PARTTIME": "Temps partiel",
    "TEMPORARY": "Temporaire",
    "CONTRACTUAL": "Contractuel",
    "RETIRED": "Retraité",
    "SERVICES_EAP": "Programme d'aide aux employés (PAE)",
    "SERVICES_WORK": "Service de psychologie/travail social CNESST, IVAC, Santé Canada, Croix Bleue, autre",
    "SERVICES_PRIVATE": "Service de psychologie/travail social privé",
    "SERVICES_NEURO": "Service de Neuropsychologie",
    "SERVICES_ABC": "Service Clinique ABC en privé",
    "SERVICES_MEDIATION": "Service de médiation, résolution de conflit au travail, harcèlement psychologique et autres",
    "YES": "Oui",
    "NO": "Non",
    "DOCTOR": "Recommandation du médecin",
    "SUGGESTION_FRIEND": "Suggestion d'un confrère/ami",
    "RECOMMENDATION_EMPLOYER": "Recommendation de l'employeur",
    "SUGGESTION_FAMILY": "Suggestion de la famille",
    "RECOMMENDATION_UNION": "Recommendation syndicale",
    "GVT_QC": "Gouvernement Québec",
    "ALREADY_VIEWED": "Déjà consulté",
    "GVT_CA": "Santé Canada",
    "Reports": "Rapports",
    "EAP_AD": "Publicité du PAE",
    "RECOMMENDATION_DOCTOR": "Recommandation du médecin",
    "Give access": "Donner accès",
    "Remove the access": "Retirer l'accès",
    "Assistant": "Assistant",
    "Administrator": "Administrateur",
    "Users": "Utilisateurs",
    "Role": "Rôle",
    "Last access": "Dernier accès",
    "Invite user": "Inviter un utilisateur",
    "Meetings list": "Liste des rencontres",
    "From": "De",
    "To": "À",
    "Run report": "Exécuter le rapport",
    "Coming": "À venir",
    "Missing follow-up": "Suivi manquant",
    "Done": "Terminé",
    "Waiting": "En attente",
    "Deleted": "Supprimé",
    "Scheduled": "Planifié",
    "Meetings requests": "Demandes de rencontres",
    "Restore the meeting": "Restaurer la rencontre",
    "Are-you sure you want to restore this meeting?": "Êtes-vous sûr de vouloir restaurer la rencontre?",
    "Date": "Date",
    "User": "Utilisateur",
    "Request url": "Url de la requête",
    "Request body": "Contenu de la requête",
    "Action": "Action",
    "Logs": "Registres",
    "Request body copied to clipboard": "Le contenu de la requête a été copié dans le presse-papier",
    "Schedule": "Horaire",
    "Schedule exceptions": "Exceptions à l'horaire",
    "End": "Fin",
    "Working": "Travail",
    "Add": "Ajouter",
    "Remove": "Retirer",
    "File number": "Numéro de dossier",
    "Schedule a follow-up appointment": "Planifier une rencontre de suivi",
    "Follow-up": "Suivi",
    "Validated previous session": "Validation d'une session précédente",
    "Created a meeting": "Création d'une rencontre",
    "Changed a status meeting": "Modification du statut d'une rencontre",
    "Created a patient": "Création d'un patient",
    "Changed a user role": "Changement du rôle d'un utilisateur",
    "Updated a patient": "Modification d'un patient",
    "Number of meetings": "Nombre de rencontres",
    "Number of professionals": "Nombre de professionnels",
    "Password": "Mot de passe",
    "Forgot password": "Mot de passe oublié",
    "Sign in": "Se connecter",
    "Remember your password?": "Retour à la connection",
    "Have a code?": "J'ai un code",
    "Get a password reset code": "Obtenir un code de réinitialisation",
    "Code": "Code",
    "Reset password": "Réinitialiser le mot de passe",
    "Bad email or password": "Courriel ou mot de passe incorrecte",
    "If this email has access, you will receive a reset code.": "Si ce courriel a accès, vous recevrez un code de réinitialisation",
    "An email has been sent with the new password": "Un courriel a été envoyé avec le nouveau mot de passe",
    "The reset code is not valid": "Le code de réinitialisation n'est pas valide",
    "Auto save": "Sauvegarde automatique",
    "Note model": "Modèle de note",
    "Apply note model?": "Appliquer le modèle de note?",
    "You will lost any entered note. Do you want to continue?": "Les notes saisies seront perdues. Voulez-vous continuer?",
    "Email notifications": "Notifications courriel",
    "SMS notifications": "Notifications SMS",
    "Use a contract extension": "Utilise une extension de contrat",
    "There is no remaining meetings left. Can this patient use a contract extension?": "Il n'y a plus de rencontre restante. Est-ce que ce patient peut utiliser une extension de contrat?",
    "Professional not available": "Intervenant non disponible",
    "The professional is not available at this schedule. Are you sure you want to schedule this meeting?": "L'intervenant n'est pas disponible à cette plage horaire. Êtes-vous sûr de vouloir planifier cette rencontre?",
    "View": "Voir",
    "Extension": "Extension",
    "To come": "À venir",
    "Confirmed": "Confirmé",
    "View deleted contact requests": "Voir les demandes de rencontre supprimées",
    "Add schedule exception": "Ajouter une exception",
    "This meeting is on a contract extension.": "Cette rencontre est sur une extension de contrat.",
    "Please make sure you have permission to schedule this meeting.": "S.V.P. assurez-vous d'avoir la permission de planifier cette rencontre.",
    "You must pass a two-factor authentication to continue": "Vous devez passer une authentification à deux facteurs pour continuer",
    "Obtain code by SMS": "Obtenir un code par SMS",
    "Obtain code by Email": "Obtenir un code par courriel",
    "A code has been sent to": "Un code a été envoyé au",
    "Validate": "Valider",
    "The two-factor authentication is not valid": "L'authentification à deux facteurs n'est pas valide",
    "Phone": "Téléphone",
    "Send": "Envoyer",
    "Meeting date": "Date de rencontre",
    "Special account": "Compte spécial",
    "Ensure that you're authorized to set this meeting on the special account!": "Assurez-vous d'avoir l'autorisation pour planifier cette rencontre sur le compte spécial!",
    "A cycle report must be uploaded": "Un rapport de cycle doit être téléchargé",
    "Color": "Couleur",
    "Choose a color": "Choisissez une couleur",
    "CSST": "CSST",
    "DOCUMENT": "DOCUMENT",
    "Request": "Demande",
    "Unknown": "Inconnu",
    "User signed in": "Un utilisateur s'est connecté",
    "System maintenance task invoked": "Tâche de maintenance système invoqué",
    "Updated a contact request": "Mise à jour d'une demande de rencontre",
    "Two FA validation success": "Authentification à deux facteurs validé avec succès",
    "Changed a user status": "Modification d'un statut utilisateur",
    "Two FA validation requested": "Demande d'authentification à deux facteurs",
    "A new external contact request has been saved": "Une nouvelle demande de rencontre a été enregistrée",
    "User failed to sign in": "Un utilisateur a échoué à s'authentifier",
    "Created a follow-up for a meeting": "Création d'un suivi pour une rencontre",
    "Uploaded a signature": "Téléversement d'une signature",
    "Changed his/her schedule": "Modification d'un horaire utilistateur",
    "Updated a meeting": "Mise à jour d'une rencontre",
    "Attached files": "Fichiers attachés",
    "Upload": "Téléverser",
    "No files attached": "Aucun fichier attaché",
    "Include details": "Inclure les détails",
    "Signature": "Signature",
    "Import signature": "Téléverser la signature",
    "Downloaded a patient file": "Téléchargement d'un fichier d'un patient",
    "Uploaded a patient file": "Téléversement d'un fichier d'un patient",
    "Old password": "Ancien mot de passe",
    "New password": "Nouveau mot de passe",
    "Confirm password": "Confirmer le mot de passe",
    "Change password required": "Modification du mot de passe requis",
    "Length between 8 and 20 characters": "Longueur entre 8 et 20 caractères",
    "At least one lower case character": "Au moins un caractère minuscule",
    "At least one upper case character": "Au moins un caractère majuscule",
    "At least one numeric case character": "Au moins un caractère numérique",
    "Password confirmation": "Confirmation du mot de passe",
    "Change password": "Modifier le mot de passe",
    "Password changed successfully": "Mot de passe modifié avec succès",
    "Please complete your profile": "S.V.P. complétez votre profil",
    "Restore": "Restaurer",
    "Profile changed successfully": "Profil modifié avec succès",
    "PHONE": "TÉLÉPHONE",
    "SMS": "SMS",
    "EMAIL": "COURRIEL",
    "VOICE": "VOIX",
    "Invited a new user": "A invité un nouvel utilisateur",
    "Changed a user name": "Modifié un nom d'utilisateur",
    "User changed his password": "A changé son mot de passe",
    "New signature saved": "Nouvelle signature sauvegardée",
    "An error occured. Please check your old password": "Une erreur est survenue. S.V.P. vérifiez votre ancien mot de passe.",
    "Contact requests list": "Liste des demandes de rencontre",
    "Transmitted": "Transmit",
    "invalid format": "Format invalide",
    "Security": "Sécurité",
    "Today": "Aujourd'hui",
    "Meeting missed": "Rencontre manquée",
    "Are-you sure you want to mark this meeting as missed?": "Êtes-vous sûr de vouloir indiquer cette rencontre comme manquée?",
    "Complete follow-up": "Compléter le suivi",
    "Are-you sure you want to complete the follow-up of this meeting?": "Êtes-vous sûr de vouloir compléter le suivi de cette rencontre?",
    "Link": "Lien",
    "Google meet": "Google meet",
    "Microsoft Teams": "Microsoft Teams",
    "Zoom": "Zoom",
    "Meeting completed": "Rencontre complétée",
    "Absence": "Absence",
    "Cancel the absence": "Annuler l'absence",
    "Are-you sure you want to cancel this absence?": "Êtes-vous sûr de vouloir annuler cette absence?",
    "PAYMENT_RECEIPT": "Reçu de paiement",
    "No organization": "Aucune organisation",
    "Unconfirm presence": "Annuler la confirmation de présence",
    "Search": "Rechercher",
    "Print contact request": "Imprimer la demande de contact",
    "Medication": "Médication",
    "Paid": "Payé",
    "Timesheet": "Feuille de temps",
    "All professionals": "Tous les professionnels",
    "Uncomplete follow-up": "Décompléter le suivi",
    "Are-you sure you want to uncomplete the follow-up of this meeting?": "Êtes-vous sûr de vouloir décompléter le suivi de cette rencontre?",
    "Allow to uncomplete": "Permettre de décompléter",
    "Disallow to uncomplete": "Ne pas permettre de décompléter",
    "In person": "En personne",
    "Videoconference": "Conf. vidéo",
    "Ext.": "Ext.",
    "Place": "Emplacement",
    "Places": "Emplacements",
    "Place name": "Nom de l'emplacement",
    "Missing reports": "Rapports manquants",
    "Deactivate this patient?": "Désactiver ce patient?",
    "Reactivate this patient?": "Réactiver ce patient?",
    "With accounts": "Avec comptes",
    "Without accounts": "Sans comptes",
    "Contractor": "Contracteur",
    "Activate": "Activer",
    "Allowed": "Permis",
    "Took": "Pris",
    "Remaining": "Restants",
    "Concurrency error": "Erreur de concurrence",
    "Another screen appears to be opened for this follow-up. Please be sure to edit this follow-up in one place only.": "Un autre écran semble s'être ouvert pour ce suivi. Assurez-vous de modifier ce suivi à un seul endroit. Si ce n'est pas le cas, vous pouvez rouvrir cet écran dans 30 secondes ou ne pas tenir compte de ce message.",
    "Documents": "Documents",
    "Evaluation": "Évaluation",
    "Evolution": "Évolution",
    "Final": "Final",
    "Note history": "Historique des notes",
    "No available history": "Aucun historique disponible",
    "Apply note history?": "Appliquer l'historique de la note?",
    "All patients": "Tous les patients",
    "Consultation reason": "Motif de consultation",
    "Event date": "Date de l'événement",
    "File number IVAC/CSST": "Numéro de dossier IVAC/CSST",
    "Mother": "Mère",
    "Father": "Père",
    "Other": "Autre",
    "Relationship": "Lien de parenté",
    "Apply": "Appliquer",
    "Parent 1": "Parent 1",
    "Parent 2": "Parent 2",
    "Your changes will be lost. Are you sure you want to leave this page?": "Vos changements seront perdus. Êtes-vous sûr de vouloir quitter cette page?",
    "Primary": "Primaire",
    "Secondary": "Secondaire",
    "Remarks": "Remarques",
    "ANXIOUS": "Trouble anxieux",
    "PERSONALITY": "Trouble de personnalité",
    "MOOD": "Trouble de l'humeur",
    "ADAPTATION": "Trouble d'adaptation",
    "PROFESSIONAL": "Problèmes professionnels",
    "COUPLE": "Problèmes relationnels / couple",
    "FAMILY": "Problèmes relationnels / famille",
    "LEGAL": "Problèmes juridiques",
    "FINANCIAL": "Problèmes financiers",
    "ADDICTION": "Toxicomanie",
    "GAMBLING": "Problèmes de jeu",
    "GRIEF": "Deuil",
    "OTHER": "Autres",
    "Consultation reasons": "Raisons de consultations",
    "Suicidal ideation": "Idéation suicidaire",
    "Name of treating doctor": "Nom du médecin traitant",
    "Allowed patients": "Patients autorisés",
    "File imported successfully": "Fichier importé avec succès",
    "Import": "Importer",
    "This patient is not authorized for this account": "Ce patient n'est pas autorisé pour ce compte",
    "All accounts": "Tous les comptes",
    "Professionals": "Professionnels",
    "Previous": "Précédents",
    "Personal note": "Note personnelle",
    "Save note model": "Sauvegarder en tant que modèle",
    "Model name": "Nom du modèle",
    "New model name": "Nom du nouveau modèle de note",
    "Remove note model": "Retirer le modèle de note",
    "Are you sure you want to remove this note model?": "Êtes-vous sûr de vouloir retirer ce modèle de note?",
    "Missing cycle reports": "Rapports de cycle manquants",
    "Unfinished cycles": "Cycles non complétés",
    "New contact requests": "Nouvelle demande de rencontre",
    "RAMQ Number": "Numéro RAMQ",
    "Expir. month": "Mois d'expir.",
    "Expir. year": "Année d'expir.",
    "Informations": "Informations",
    "Contact": "Contact",
    "RAMQ": "RAMQ",
    "IVAC/CSST": "IVAC/CSST",
    "PSYCHIATRY": "Service de psychiatrie",
    "Service": "Service",
    "Paid meetings": "Rencontres payées",
    "Unpaid meetings": "Rencontres non payées",
    "Inbound sms messages": "Messages SMS entrants",
    "Export to excel": "Exporter vers excel",
    "Statistics": "Statistiques",
    "Rates": "Taux",
    "Payment receipts": "Reçu de paiements",
    "Include unpaid meetings": "Inclure les rencontrer non payées",
    "Amount paid": "Montant payé",
    "Identity": "Identité",
    "Licence number": "Numéro de permis de pratique",
    "Tel.": "Tél.",
    "Service provided to": "Services dispensés à",
    "Missed meetings rates": "Taux des rencontres manquées",
    "Rate": "Taux",
    "No meeting": "Aucune rencontre",
    "Administrative fees (24-hour cancellation or absence from the appointment)": "Frais administratifs (annulation moins de 24 ou absence au rendez-vous)",
    "Settings": "Configurations",
    "Can be scheduled": "Visible dans la cédule",
    "Transfer to contractors": "Transférer aux contracteurs",
    "Transfer to professionals": "Transférer aux professionnels",
    "The patient asked to cancel this meeting": "Le patient a demandé d'annuler la rencontre",
    "on": "le",
    "at": "à",
    "Patient has answered to the meeting confirmation": "Un patient a répondu à la confirmation de rendez-vous",
    "A patient has confirmed their presence at an appointment": "Un patient a confirmé sa présence à un rendez-vous",
    "A patient has requested the cancellation of an appointment": "Un patient a demandé l'annulation d'un rendez-vous",
    "Paid amount": "Montant payé",
    "Eligible employees": "Employés élligibles",
    "Report viewer": "Visualisateur de rapport",
    "Send report": "Envoyer le rapport",
    "Emails": "Courriels",
    "Email subject": "Sujet du courriel",
    "Email body": "Texte du courriel",
    "Report sent successfully": "Rapport envoyé avec succès",
    "An error occured while sending the report. Please try again.": "Une erreur est survenue lors de l'envoi. S.V.P, essayez à nouveau.",
    "Announcements": "Annonces",
    "Announcement": "Annonce",
    "Title": "Titre",
    "Message": "Message",
    "Expiry": "Expiration",
    "Are you sure you want to delete this announcement?": "Êtes-vous sûr de vouloir supprimer cette annonce?",
    "Delete this announcement?": "Supprimer cette annonce?",
    "Importance": "Importance",
    "Information": "Information",
    "Normal": "Normal",
    "Warning": "Attention",
    "Urgent": "Urgent",
    "New email": "Nouveau courriel",
    "Refresh": "Rafraîchir",
    "Reply": "Répondre",
    "Reply All": "Répondre à tous",
    "Print email": "Imprimer le courriel",
    "Delete email": "Supprimer le courriel",
    "Are you sure you want to delete this email?": "Êtes-vous sûr de vouloir supprimer ce courriel?",
    "Signed documents": "Documents signés",
    "Discard draft": "Supprimer le brouillon",
    "Are you sure you want to discard your draft document?": "Êtes-vous sûr de vouloir supprimer le brouillon?",
    "Add document": "Ajouter document",
    "Delete document": "Supprimer document",
    "Are you sure you want to delete this document? Patients will not be able to view it anymore.": "Êtes-vous sûr de vouloir supprimer ce document? Les patients ne pourront plus le visionner par la suite.",
    "Consignees": "Destinataires",
    "Subject": "Sujet",
    "Are you sure you want to discard your draft message?": "Êtes-vous sûr de vouloir supprimer ce brouillon?",
    "No signed document configured": "Aucun document configuré",
    "No answer received": "Aucune réponse reçue",
    "Accept": "Accepter",
    "Decline": "Refuser",
    "You already accepted this document": "Vous avez déjà accepté ce document",
    "You already declined this document": "Vous avez déjà refusé ce document",
    "Send on patient creation (only one document can be selected)": "Envoyer à la création d'un patient (seulement un document peut être sélectionné)",
    "Send document": "Envoyer le document",
    "Are you sure you want to send this document?": "Êtes-vous sûr de vouloir envoyer ce document?",
    "Signed Documents": "Documents signés",
    "Send documents": "Envoyer des documents",
    "Received answers": "Réponses reçues",
    "Document sent successfully": "Document envoyé avec succès",
    "Decline this document": "Refuser ce document",
    "Are you sure you want to decline this document?": "Êtes-vous sûr de vouloir refuser ce document?",
    "Answer has been received. Thank you": "La réponse a été bien reçue. Merci",
    "Received without comm": "Reçu sans communication",
    "Received with comm": "Reçu avec communication",
    "Hours Summary": "Sommaire des heures",
    "Week": "Semaine",
    "Month": "Mois",
    "Current": "Courant",
    "Filter": "Filtre",
    "Hours Follow-up": "Suivi des heures",
    "Current (daily)": "Courant (quotidien)",
    "Current (sum)": "Courant (cumul)",
    "Planned (daily)": "Planifié (quotidien)",
    "Planned (sum)": "Planifié (cumul)",
    "Previous (daily)": "Précédent (quotidien)",
    "Previous (sum)": "Précédent (cumul)",
    "Empty subject": "Sujet vide",
    "You will send email to external contact": "Vous vous apprêtez à envoyer un courriel à un contact externe à votre organisation",
    "Next": "Suivant",
    "Submit": "Soumettre",
    "Previous_single": "Précédent",
    "About you": "À propos de vous",
    "Q: Used service past": "Avez déjà utilisé nos services dans le passé?",
    "Q: Requested service": "Pour quel service faites vous appel à notre équipe?",
    "Your comments and reasons for your request": "Vos commentaires et motifs de votre demande",
    "Name of employer or organization": "Nom de l'employeur ou de l'organisation",
    "Connection to the company or organization": "Lien avec l'entreprise ou l'organisation",
    "Name of person employed by the company": "Nom de la persone employé de l'entreprise",
    "How long have you been employed?": "À l'emploi depuis combien de temps ?",
    "Directed to EAP": "Dirigé vers le PAE",
    "Confirmation number": "# Confirmation",
    "Thank you for contacting us": "Merci de nous avoir contacté",
    "Here is your confirmation number": "Voici votre numéro de confirmation",
    "A patient arrived": "Un patient est arrivé",
    "Welcome": "Bienvenue",
    "Please enter your meeting code here": "S.V.P. entrez votre code de rencontre ici",
    "Thank you! Please have a seat": "Merci! S.V.P. prenez un siège",
    "Sorry, this code is not valid": "Désolé, ce code n'est pas valide",
    "Return": "Retour",
    "MALE": "Homme",
    "FEMALE": "Femme",
    "NON_BINARY": "Non binaire",
    "Gender": "Genre",
    "Access to notes": "Accès aux notes",
    "24H": "24H",
}
export default local_fr;
