import React, {RefObject} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../store/store";
import ReactDOM from "react-dom";
import ReportStyle from './reports.styled';
import {logException} from "../../controllers/system";
import {localize} from "../../helpers/localization";
import FullScreenDialog from "../ui/fullScreenDialog/fullScreenDialog";
import {Patient} from "../../models/patient";
import User from "../../models/user";
import SendToDialog from "../ui/sendToDialog/sendToDialog";
import {Contact} from "../../models/contact";
import {sendReport} from "../../helpers/reports";
import {IAppNotification} from "../../models/appNotification";
import {setAppNotification} from "../../store/actions/app";

type IState = {
    open: boolean,
    report: string
}

type IProps = {
    children: React.ReactElement,
    title: string,
    patients?: Array<Patient>,
    users?: Array<User>,
    defaultSendToContacts?: Array<Contact>
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang,
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class ReportViewer extends React.Component<ReduxType, IState> {
    public state: IState = {
        open: false,
        report: ''
    };

    private dialogRef: RefObject<any>;
    private iframeRef: RefObject<any>;
    private sendToDialogRef: RefObject<any>;
    private generatedReport?: string;

    constructor(props: ReduxType) {
        super(props);
        this.dialogRef = React.createRef();
        this.iframeRef = React.createRef();
        this.sendToDialogRef = React.createRef();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    onModalButtonClick(key: string) {
        switch (key) {
            case 'print':
                this.print();
                break;

            case 'send':
                this.sendToDialogRef.current.openDialog();
                break;
        }
    }

    closeDialogRequest(): boolean {
        this.setState((state: IState) => {return {...state, open: false};});
        return true;
    }

    print() {
        let iframeWindow = this.iframeRef.current.contentWindow || this.iframeRef.current.contentDocument.defaultView;
        iframeWindow.print();
    }

    async run() {
        await this.dialogRef.current.openDialog();
        let e = document.createElement('div');
        ReactDOM.render(this.renderReport(), e, () => {
            this.generatedReport = e.outerHTML;
            this.setState(
                (state: IState) => {
                    return {
                        ...state,
                        open: true,
                        report: e.outerHTML
                    };
                },
                () => {
                    let iframeWindow = this.iframeRef.current.contentWindow || this.iframeRef.current.contentDocument.defaultView;
                    iframeWindow.document.write(this.state.report);
                }
            );
        });
    }

    renderReport(): React.ReactElement {
        return (
            <div style={{fontFamily: 'verdana, arial', fontSize: '14px'}}>
                <ReportStyle />
                {this.props.children}
            </div>
        );
    }

    async sendReport(contacts: Array<Contact | string>, subject: string, body: string): Promise<boolean> {
        let success: boolean = await sendReport(this.generatedReport as string, contacts, subject, body);
        if (success) {
            this.props.setAppNotification({severity: 'success', message: 'Report sent successfully'});
            this.sendToDialogRef.current.closeDialog();
        } else {
            this.props.setAppNotification({severity: 'error', message: 'An error occured while sending the report. Please try again.'});
        }

        return success;
    }

    render() {
        return (
            <FullScreenDialog
                ref={this.dialogRef}
                title={this.props.title}
                onButtonClick={this.onModalButtonClick.bind(this)}
                onCloseRequest={this.closeDialogRequest.bind(this)}
                buttons={[
                    {key: 'send', text: localize('Send', this.props.lang), color: 'warning', keepDialogOpen: true},
                    {key: 'print', text: localize('Print', this.props.lang), color: 'info', keepDialogOpen: true, disabled: !this.state.open}
                ]}
            >
                <iframe
                    title={'report'}
                    ref={this.iframeRef}
                    style={{flexGrow: 1}}
                />
                <SendToDialog
                    ref={this.sendToDialogRef}
                    patients={this.props.patients}
                    users={this.props.users}
                    title={localize('Send report', this.props.lang)}
                    defaultContacts={this.props.defaultSendToContacts}
                    onSend={this.sendReport.bind(this)}
                />
            </FullScreenDialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(ReportViewer);
