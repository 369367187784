import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {
    Box,
    Button,
    Checkbox,
    Divider, FormControlLabel,
    IconButton, InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText, Switch, TextField,
    Typography
} from "@mui/material";
import Text from "../../../app/text/text";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Calendar from "@toast-ui/react-calendar";
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import User from "../../../../models/user";
import {getUserFullName} from "../../../../controllers/user";
import dayjs from "dayjs";
import {localize} from "../../../../helpers/localization";
import Meeting, {
    COMMUNCATION_CHANNEL,
    MEETING_STATUS,
    MeetingWithPatient,
    PATIENT_ANSWER
} from "../../../../models/meeting";
import {getMeetings} from "../../../../controllers/meetings";
import {ROLES} from "../../../../constants/roles";
import {IScheduleDay, IScheduleSlot} from "../../../../models/schedule";
import {logException} from "../../../../controllers/system";
import {formatFileNumber} from "../../../../controllers/patients";
import {darken} from "@mui/material/styles";
import {Place} from "../../../../models/place";
import {getPlaceAcronym} from "../../../../controllers/place";
import * as localStorage from "../../../../helpers/localStorage";
import {KEYS} from "../../../../helpers/localStorage";
import {emphasize} from "@mui/system/colorManipulator";
import { renderToString } from 'react-dom/server';
import CancelIcon from "@mui/icons-material/Cancel";

type IState = {
    selectedUsers: string[],
    meetings: Meeting[],
    schedules: any[],
    scheduleDate: Date,
    calendars: any[],
    displayCalendar: boolean,
    professionalFilter: string,
    filteredProfessionals: User[],
    show24H: boolean
}

type IProps = {
    professionals: User[],
    places: Place[],
    onAddClick: (meeting?: Meeting) => void,
    onMeetingSelected: (meeting: Meeting) => void
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang,
        userRole: state.user.role,
        userId: state.user._id
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {};
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

let calendarTemplates: any = {
    milestone: (schedule: any) => {
        return `<span style="color:#fff;background-color: ${schedule.bgColor};">545645644654${
            schedule.title
        }</span>`;
    },
    milestoneTitle: () => {
        return localize('Milestone', 'fr');
    },
    allday: (schedule: any) => {
        return `${schedule.title}`;
    },
    alldayTitle: () => {
        return '';
    },
    timegridDisplayPrimaryTime: (data: any) => {
        let time = dayjs(data.time);
        return dayjs().startOf('day').hour(time.hour()).minute(time.minute()).format('HH:mm');
    },
    timegridNowIndicatorLabel: (data: any) => {
        return dayjs(data.time).format('HH:mm');
    },
    weekDayName: (model: any) => {
        return `<span class="tui-full-calendar-dayname-date-area" style="color: #bbb;">
                    <span class="tui-full-calendar-dayname-date">${model.date}</span>&nbsp;&nbsp;<span class="tui-full-calendar-dayname-name">${dayjs().day(model.day).format('ddd')}</span>
                </span>`;
    },
    timegridDisplayTime: (props: any) => {
        return 'test';
    },
    time: (schedule: any) => {
        return schedule.title;
    }
}

class Scheduler extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        selectedUsers: [],
        meetings: [],
        schedules: [],
        scheduleDate: dayjs().startOf('week').toDate(),
        calendars: [],
        displayCalendar: false,
        professionalFilter: '',
        filteredProfessionals: [],
        show24H: localStorage.get(this.props.userId, localStorage.KEYS.SHOW_24H) === 'true'
    }

    private indexedPlaces: any = {};
    private calendarOffset: number = 0;
    private calendarRef: React.RefObject<any>;

    constructor(props: ReduxType) {
        super(props);
        this.calendarRef = React.createRef();
    }

    componentDidMount() {
        window.setTimeout(() => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    displayCalendar: true
                };
            });
        }, 500);
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    setCalendars(): Promise<void> {
        return new Promise((resolve) => {
            let calendars: any[] = [];
            this.setState((state: IState) => {
                for (let professional of this.state.filteredProfessionals) {
                    calendars.push({
                        id: professional._id,
                        name: getUserFullName(professional),
                        color: 'white',
                        backgroundColor: professional.color,
                        borderColor: emphasize(professional.color || '#000000', 0.2)
                    });
                }
                return {
                    ...state,
                    calendars
                };
            }, resolve);
        });

    }

    componentDidUpdate(prevProps: Readonly<ReduxType>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.professionals.length !== this.props.professionals.length) {
            this.setProfessionalFilter('').then(() => {
                this.indexPlaces();
                this.setCalendars().then(() => {
                    this.setSelectedUsers().then(() => {
                        this.refreshSchedule();
                    });
                });
            });
        }
    }

    indexPlaces() {
        this.indexedPlaces = {};
        for (let place of this.props.places) {
            this.indexedPlaces[place._id as string] = place;
        }
    }

    setSelectedUsers(selected?: boolean): Promise<void> {
        return new Promise((resolve) => {
            this.setState((state: IState) => {
                let selectedUsers: string[] = [];
                if (selected === true) {
                    // On newly selected from user
                    this.state.filteredProfessionals.map((professional) => {
                        selectedUsers.push(professional._id as string);
                        return null;
                    });
                } else if (selected === undefined && this.state.filteredProfessionals.length === 1) {
                    // When loading screen with a professionnal profile
                    selectedUsers.push(this.state.filteredProfessionals[0]._id as string);
                } else if (selected === undefined) {
                    // When loading screen with assistant or admin profile
                    selectedUsers = this.loadUserSelection();
                }
                return {
                    ...state,
                    selectedUsers
                };
            }, () => {
                if (selected !== undefined) {
                    this.saveUserSelection();
                    this.refreshSchedule();
                }

                resolve();
            });
        });
    }

    refreshSchedule() {
        this.getSchedules().then((schedules: any[]) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    schedules
                };
            });
        });
    }

    toggleUser(user: User) {
        this.setState((state: IState) => {
            let selectedUsers: string[] = Object.assign([], this.state.selectedUsers);
            let existingIndex = selectedUsers.indexOf(user._id as string);
            if (existingIndex >= 0) selectedUsers.splice(existingIndex, 1);
            else selectedUsers.push(user._id as string);

            return {
                ...state,
                selectedUsers
            };
        }, () => {
            this.saveUserSelection();
            this.refreshSchedule();
        });
    }

    saveUserSelection() {
        localStorage.set(this.props.userId, KEYS.SCHEDULER_SELECTION, this.state.selectedUsers.join(','));
    }

    loadUserSelection(): string[] {
        let selectedUsers: string | null = localStorage.get(this.props.userId, KEYS.SCHEDULER_SELECTION);

        if (selectedUsers) return selectedUsers?.split(',') || [];
        else return [];
    }

    getSchedules(): Promise<any[]> {
        return new Promise((resolve) => {
            let schedules: any[] = this.getProfessionalSchedules();
            this.loadMeetings().then((meetings: any[]) => {
                schedules = schedules.concat(meetings);
                resolve(schedules);
            });
        });
    }

    getProfessionalSchedules(): any[] {
        let schedules: any[] = [];

        for (let weekDay = 0; weekDay < 7; weekDay++) {
            let day: dayjs.Dayjs = dayjs(this.state.scheduleDate).add(weekDay, 'day');

            for (let professional of this.state.filteredProfessionals) {
                if (this.state.selectedUsers.indexOf(professional._id as string) === -1) continue;
                let startHr: number = 8,
                    startMin: number = 0,
                    endHr: number = 17,
                    endMin: number = 0;

                if (professional.schedule && Array.isArray(professional.schedule.base)) {
                    if (professional.schedule.base[day.day()].working === false || !Array.isArray(professional.schedule.base[day.day()].slots)) continue;
                    let scheduleDay: IScheduleDay = professional.schedule.base[day.day()] as IScheduleDay;

                    if (scheduleDay.slots) {
                        let startSlot: IScheduleSlot = scheduleDay.slots[0] as IScheduleSlot;

                        let dayStart: dayjs.Dayjs = dayjs(startSlot.start);
                        if (dayStart.isValid()) {
                            startHr = dayStart.hour();
                            startMin = dayStart.minute();
                        }
                        let dayEnd: dayjs.Dayjs = dayjs(scheduleDay.slots[scheduleDay.slots?.length - 1].end);
                        if (dayEnd.isValid()) {
                            endHr = dayEnd.hour();
                            endMin = dayEnd.minute();
                        }
                    }
                } else {
                    if (day.day() === 0 || day.day() === 6) continue;
                }

                let start: dayjs.Dayjs = day.hour(startHr).minute(startMin).second(0).millisecond(0);
                let end: dayjs.Dayjs = day.hour(endHr).minute(endMin).second(0).millisecond(0);
                schedules.push({
                    id: professional._id,
                    calendarId: professional._id,
                    title: `${getUserFullName(professional)} ${start.format('HH:mm')} - ${end.format('HH:mm')}`,
                    category: 'allday',
                    start: start.toISOString(),
                    end: end.toISOString()
                });
            }
        }

        return schedules;
    }

    loadMeetings(): Promise<any[]> {
        return new Promise((resolve => {
            getMeetings({
                dateFrom: this.state.scheduleDate,
                dateTo: dayjs(this.state.scheduleDate).add(7, 'days').subtract(1, 'millisecond').toDate(),
                userIds: this.state.selectedUsers,
                meetingStatus: [
                    MEETING_STATUS.Created,
                    MEETING_STATUS.Confirmed,
                    MEETING_STATUS.Completed,
                    MEETING_STATUS.Reported,
                    MEETING_STATUS.Missed,
                    MEETING_STATUS.CanceledByPatient,
                ]
            }).then((meetings: MeetingWithPatient[]) => {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        meetings
                    }
                }, () => {
                    resolve(meetings.map((meeting) => {
                        return {
                            id: meeting._id,
                            calendarId: meeting.professionalUserId,
                            title: this.getMeetingTitle(meeting),
                            category: 'time',
                            start: dayjs(meeting.timeFrom).toISOString(),
                            end: dayjs(meeting.timeTo).toISOString(),
                            customStyle: {opacity: (
                                (meeting.status as MEETING_STATUS) === MEETING_STATUS.Missed
                                || (meeting.status as MEETING_STATUS) === MEETING_STATUS.Canceled
                                || (meeting.status as MEETING_STATUS) === MEETING_STATUS.CanceledByPatient
                                || meeting.isBlock
                                ) ? 0.6 : 1}
                        };
                    }));
                });

            });
        }));
    }

    getMeetingTitle(meeting: MeetingWithPatient): string {
        if (!meeting.isBlock) {
            let icon: JSX.Element | null = null;
            let patientAnswer: JSX.Element | null = null;
            switch (meeting.patientAnswer) {
                case PATIENT_ANSWER.Canceled:
                    patientAnswer = (
                        <i
                            className={'fa-solid fa-triangle-exclamation fa-beat-fade'}
                            style={{marginRight: '0.5em'}}
                        ></i>
                    )
                    break;
                case PATIENT_ANSWER.Confirmed:
                    patientAnswer = (
                        <i
                            className={'fa-solid fa-check'}
                            style={{marginRight: '0.5em'}}
                        ></i>
                    )
                    break;
            }

            switch (meeting.communicationChannel) {
                case COMMUNCATION_CHANNEL.Person:
                    icon = <i className={'fa-solid fa-building'} style={{marginRight: '0.5em'}}></i>;
                    if (meeting.placeId) {
                    }
                    break;
                case COMMUNCATION_CHANNEL.Phone:
                    icon = <i className={'fa-solid fa-phone'} style={{marginRight: '0.5em'}}></i>;
                    break;
                case COMMUNCATION_CHANNEL.Visio:
                    icon = <i className={'fa-solid fa-camera-web'} style={{marginRight: '0.5em'}}></i>;
                    break;
            }

            return (renderToString(
                <div style={{whiteSpace: 'initial', fontWeight: 'bold'}}>
                    {patientAnswer}
                    {icon}
                    {meeting.placeId ? `(${getPlaceAcronym(this.indexedPlaces[meeting.placeId])}) ` : null}
                    {meeting.paidByPatient ? <i className="fa-solid fa-dollar-sign"></i> : null}{meeting.paidByPatient ? ' ' : null}
                    {this.getMeetingStatusTitle(meeting)}
                    {formatFileNumber(meeting.patient?.fileNumber)}<span> - </span>
                    {meeting.patient?.firstName}<span>&nbsp;</span>{meeting.patient?.lastName}
                </div>
            ));
        } else {
            return `<div style="white-space: initial;font-weight: bold;">${localize('Absence', this.props.lang)}${(meeting.note ? ` - ${meeting.note}` : '')}</div>`;
        }
    }

    getMeetingStatusTitle(meeting: Meeting): string {
        switch (meeting.status as MEETING_STATUS) {
            case MEETING_STATUS.Missed:
                return `${localize('Missed', this.props.lang)} `;
            case MEETING_STATUS.Canceled:
            case MEETING_STATUS.CanceledByPatient:
                return `${localize('Canceled', this.props.lang)} `;
            default:
                return '';
        }
    }

    changeWeek(direction: number) {
        this.setState((state: IState) => {
            return {
                ...state,
                scheduleDate: dayjs(state.scheduleDate).add(direction > 0 ? 1 : -1, 'week').toDate(),
            }
        }, () => {
            this.calendarOffset += direction;
            if (direction > 0) {
                this.calendarRef.current?.calendarInstance.next();
            } else if (direction < 0) this.calendarRef.current?.calendarInstance.prev();
            this.refreshSchedule();
        });
    }

    click(t: any) {
        //console.log(t, arguments);
    }

    onMeetingClick(args: any) {
        let scheduleId: string = args.event.id;
        for (let meeting of this.state.meetings) {
            if (meeting._id === scheduleId) {
                this.props.onMeetingSelected(meeting);
                break;
            }
        }
    }

    onEmptyMeetingClick(e: any) {
        let start: dayjs.Dayjs = dayjs(e.start);
        let end: dayjs.Dayjs = dayjs(e.start).add(1, 'hour');
        this.props.onAddClick(new Meeting({
            date: start.hour(0).minute(0).second(0).millisecond(0).toDate(),
            timeFrom: start.toDate(),
            timeTo: end.toDate()
        }));
    }

    today() {
        this.setState((state: IState) => {
            return {
                ...state,
                scheduleDate: dayjs().startOf('week').toDate(),
            }
        }, () => {
            this.calendarRef.current?.calendarInstance.move(this.calendarOffset * -1);
            this.calendarOffset = 0;
            this.refreshSchedule();
        });
    }

    setCalendarHeight(calendarHeight: string) {
        this.setState((state: IState) => {
            return {
                ...state,
                calendarHeight,
                displayCalendar: true
            };
        });
    }

    setProfessionalFilter(professionalFilter: string): Promise<void> {
        return new Promise((resolve) => {
            this.setState((state: IState) => {
                let filteredProfessionals: User[] = this.props.professionals.filter((user: User) => {
                    let regex = new RegExp(professionalFilter, 'i');
                    return regex.test(user.firstName || '') || regex.test(user.lastName || '');
                });
                return {...state, professionalFilter, filteredProfessionals};
            }, resolve);
        });

    }

    set24H(show24H: boolean) {
        this.setState((state: IState) => {
            return {
                ...state,
                show24H
            };
        }, () => {
            localStorage.set(this.props.userId, localStorage.KEYS.SHOW_24H, `${show24H}`);
        });
    }

    render() {
        return (
            <React.Fragment>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }} height={'95%'}>
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                        <Box>
                            <Typography variant={'h4'}>
                                <Text>Meetings</Text>
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Box sx={{p: 1, pr: 5}}>
                                <Typography variant={'h5'}>
                                    {dayjs(this.state.scheduleDate).format('MMMM YYYY')}
                                </Typography>
                            </Box>
                            <Box sx={{p: 1}}>
                                <FormControlLabel
                                    label={localize('24H', this.props.lang)}
                                    control={
                                        <Switch
                                            checked={this.state.show24H}
                                            onChange={(e) => {this.set24H(e.target.checked)}}
                                        />
                                    }
                                />
                            </Box>
                            <Box sx={{p: 1}}>
                                <Button color={'primary'} variant={'outlined'} size={'large'}
                                        onClick={this.today.bind(this)}>
                                    <Text>Today</Text>
                                </Button>
                            </Box>
                            <Box sx={{p: 1}}>
                                <IconButton color={'primary'} size={'large'} onClick={this.changeWeek.bind(this, -1)}>
                                    <ArrowBackIosNewIcon sx={{fontSize: '2rem'}}/>
                                </IconButton>
                            </Box>
                            <Box sx={{p: 1, pr: 5}}>
                                <IconButton color={'primary'} size={'large'} onClick={this.changeWeek.bind(this, 1)}>
                                    <ArrowForwardIosIcon sx={{fontSize: '2rem'}}/>
                                </IconButton>
                            </Box>
                            <Box sx={{p: 1}}>
                                <IconButton color={'primary'} size={'large'} onClick={() => {
                                    this.props.onAddClick();
                                }}>
                                    <AddCircleIcon sx={{fontSize: '2rem'}}/>
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{flexGrow: 1, overflow: 'hidden', display: 'flex'}}>
                        {(this.props.userRole as ROLES) !== ROLES.Professional ?
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                overflowY: 'auto',
                                overflowX: 'hidden',
                                pr: 2
                            }}>
                                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                    <Box sx={{width: '45%'}}>
                                        <Button sx={{width: '100%'}} variant="contained" color="primary"
                                                onClick={this.setSelectedUsers.bind(this, true)}>
                                            {localize('All', this.props.lang)}
                                        </Button>
                                    </Box>
                                    <Box sx={{width: '45%'}}>
                                        <Button sx={{width: '100%'}} variant="outlined" color="primary"
                                                onClick={this.setSelectedUsers.bind(this, false)}>
                                            {localize('None', this.props.lang)}
                                        </Button>
                                    </Box>
                                </Box>
                                <Box sx={{mt:1, mb:1}}>
                                    <TextField
                                        label={localize('Filter', this.props.lang)}
                                        type={'text'}
                                        sx={{width: '100%'}}
                                        size="small"
                                        value={this.state.professionalFilter}
                                        onChange={(e) => {this.setProfessionalFilter(e.target.value)}}
                                        InputProps={{
                                            endAdornment: this.state.professionalFilter ? <InputAdornment position="end">
                                                <IconButton
                                                    onClick={this.setProfessionalFilter.bind(this, '')}
                                                    edge="end"
                                                >
                                                    <CancelIcon />
                                                </IconButton>
                                            </InputAdornment> : undefined
                                        }}
                                    />
                                </Box>
                                <Box sx={{
                                    height: '250px',
                                    flexGrow: 1, '& .professionnal-list-item--contractor': {
                                        backgroundColor: (theme) => darken(theme.palette.info.main, 0.5)
                                    }
                                }}>
                                    <List sx={{}}>
                                        {this.state.filteredProfessionals.map((professional: User, index) => {
                                            if (professional.settings?.canBeScheduled !== true) return null;
                                            return (
                                                <Fragment key={professional._id || index}>
                                                    <ListItem disablePadding
                                                              className={`professionnal-list-item--${professional.contractor ? 'contractor' : 'user'}`}>
                                                        <ListItemButton onClick={() => {
                                                            this.toggleUser(professional)
                                                        }}>
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    icon={<CircleTwoToneIcon/>}
                                                                    checkedIcon={<CircleIcon
                                                                        sx={{color: professional.color}}/>}
                                                                    sx={{
                                                                        pointerEvents: 'none',
                                                                        color: professional.color
                                                                    }}
                                                                    checked={this.state.selectedUsers.indexOf(professional._id as string) !== -1}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText>{getUserFullName(professional)}</ListItemText>
                                                        </ListItemButton>
                                                    </ListItem>
                                                    <Divider/>
                                                </Fragment>
                                            );
                                        })}
                                    </List>
                                </Box>
                            </Box>
                            :
                            null
                        }

                        <Box sx={{flexGrow: 1, color: 'black',
                            '& .toastui-calendar-panel.toastui-calendar-week-view-day-names': {overflowX: 'hidden'},
                            '& .tui-full-calendar-week-container': {minHeight: 'auto !important'},
                            '& .toastui-calendar-layout.toastui-calendar-week-view': {
                                backgroundColor: 'transparent !important',
                                '& > *': {
                                    backgroundColor: 'white'
                                }
                            }
                        }}>
                            {
                                this.state.displayCalendar ?
                                    <Calendar
                                        ref={this.calendarRef}
                                        usageStatistics={false}
                                        height={'100%'}
                                        calendars={this.state.calendars}
                                        template={calendarTemplates}
                                        onSelectDateTime={this.onEmptyMeetingClick.bind(this)}
                                        onClickEvent={this.onMeetingClick.bind(this)}
                                        week={{
                                            narrowWeekend: true,
                                            startDayOfWeek: 1,
                                            hourStart: this.state.show24H ? 0 : 7,
                                            hourEnd: this.state.show24H ? 24 : 21,
                                            taskView: false
                                        }}
                                        events={this.state.schedules}
                                    />
                                    : null
                            }
                        </Box>
                    </Box>
                </Box>
            </React.Fragment>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(Scheduler);
