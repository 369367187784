import React from 'react';
import {connect} from 'react-redux';
import {AppDispatch, RootState} from "../../../../store/store";
import {Patient} from "../../../../models/patient";
import Meeting, {MEETING_STATUS} from "../../../../models/meeting";
import {localize} from "../../../../helpers/localization";
import FullScreenDialog from "../../../ui/fullScreenDialog/fullScreenDialog";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuItem,
    TextField
} from "@mui/material";
import FollowUp from "../../../../models/followUp";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import {decryptNote, getFollowUpHistory, saveFollowUp, saveFollowUpHistory} from "../../../../controllers/meetings";
import dayjs from "dayjs";
import Text from '../../../app/text/text';
import FollowUpReport from "../../../reports/followUp/followUp";
import User from "../../../../models/user";
import {getSignatureBase64, getUser} from "../../../../controllers/user";
import {IAppNotification} from "../../../../models/appNotification";
import {setAppNotification, setPreventClose} from "../../../../store/actions/app";
import ConfirmDialog from "../../../ui/confirmDialog/confirmDialog";
import ContactRequestReport from "../../../reports/contactRequest/contactRequest";
import {getContactRequest} from "../../../../controllers/contactRequests";
import {ContactRequest} from "../../../../models/contactRequest";
import {Account} from "../../../../models/account";
import {logException, preserveLock, releaseLock, reserveLock} from "../../../../controllers/system";
import AdditionalInfoDialog from "../../patients/components/additionalInfo";
import {open} from "../../../../helpers/ajax";
import {FollowUpHistory} from "../../../../models/followUpHistory";
import ConsultationReasonsDialog from "../../patients/components/consultationReasons";
import {NoteModel} from "../../../../models/noteModel";
import {getNoteModels, removeNoteModel, saveNoteModel} from "../../../../controllers/noteModel";
import ClearIcon from '@mui/icons-material/Clear';
import ReportViewer from "../../../reports/reportViewer";
import {dayjsOrAny} from "../../../../helpers/dayjs";

type IProps = {
    onSave: (followUp: FollowUp) => void,
    onPatientSave: (patient: Patient) => void,
    accounts: Account[]
}

type IState = {
    open: boolean,
    meeting?: Meeting,
    accountName?: string,
    contactRequest?: ContactRequest,
    patient?: Patient,
    professional?: User,
    followUp: FollowUp,
    changed: boolean,
    noteChanged: boolean,
    chronoStarted: boolean,
    chronoStartedAt?: Date,
    initialDuration?: number,
    autoSave: boolean,
    signature: string | null,
    lockId: string | null,
    lockError: boolean,
    lockLastPreserved: Date | null,
    history: FollowUpHistory[],
    currentNoteHistory: string,
    formValidation: any,
    noteModels: NoteModel[],
    modelNameDialogOpen: boolean,
    newModelName: string
}

const mapStateToProps = (state: RootState) => {
    return {
        lang: state.settings?.lang,
        dateFormat: state.settings?.dateFormat,
        canUncompleteFollowUp: state.user.canUncompleteFollowUp,
        userId: state.user._id
    };
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
    return {
        setAppNotification: (appNotification: IAppNotification | null) => {
            dispatch(setAppNotification(appNotification));
        },
        setPreventClose: (preventClose: boolean) => {
            dispatch(setPreventClose(preventClose));
        }
    };
};

type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

class FollowUpMeetingDialog extends React.Component<ReduxType, IState> {
    public readonly state: IState = {
        open: false,
        changed: false,
        noteChanged: false,
        chronoStarted: false,
        chronoStartedAt: undefined,
        followUp: new FollowUp({
            duration: 0,
            completed: false
        }),
        autoSave: false,
        signature: null,
        lockId: null,
        lockError: false,
        lockLastPreserved: null,
        history: [],
        currentNoteHistory: '',
        formValidation: {},
        noteModels: [],
        modelNameDialogOpen: false,
        newModelName: ''
    }

    private dialogRef: React.RefObject<any>;
    private confirmNoteModelDialogRef: React.RefObject<any>;
    private confirmCloseDialogRef: React.RefObject<any>;
    private confirmApplyNoteHistoryDialogRef: React.RefObject<any>;
    private medicationDialogRef: React.RefObject<any>;
    private consultationReasonDialogRef: React.RefObject<any>;
    private confirmRemoveNoteModelDialogRef: React.RefObject<any>;
    private reportFollowUpRef: React.RefObject<any>;
    private reportContactRequestRef: React.RefObject<any>;
    private chronoTimeout: number | undefined;
    private lastSave: Date;
    private selectingNoteModel: string;
    private _isMounted: boolean = false;
    private indexedNoteModels: any = {};
    private removingNoteModelId: string = '';

    constructor(props: ReduxType) {
        super(props);
        this.dialogRef = React.createRef();
        this.confirmNoteModelDialogRef = React.createRef();
        this.confirmCloseDialogRef = React.createRef();
        this.confirmApplyNoteHistoryDialogRef = React.createRef();
        this.medicationDialogRef = React.createRef();
        this.consultationReasonDialogRef = React.createRef();
        this.confirmRemoveNoteModelDialogRef = React.createRef();
        this.reportFollowUpRef = React.createRef();
        this.reportContactRequestRef = React.createRef();
        this.chronoTimeout = undefined;
        this.lastSave = new Date();
        this.selectingNoteModel = "";
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logException({error, errorInfo});
    }

    resetState(): Promise<void> {
        return new Promise<void>(resolve => {
            this.setState((state: IState) => {
                return {
                    open: false,
                    changed: false,
                    noteChanged: false,
                    chronoStarted: false,
                    chronoStartedAt: undefined,
                    followUp: new FollowUp({
                        duration: 0,
                        completed: false
                    }),
                    autoSave: false,
                    signature: null,
                    lockId: null,
                    lockError: false,
                    lockLastPreserved: null
                };
            }, resolve);
        });
    }

    getBlankFollowUp(): FollowUp {
        return new FollowUp({
            duration: 0,
            completed: false
        });
    }

    openDialog(patient: Patient, meeting : Meeting) {
        this.props.setPreventClose(true);
        this.getNoteModels();
        this.resetState().then(() => {
            meeting = Object.assign({}, meeting)
            getUser(meeting.professionalUserId as string).then((professional: User | null) => {
                reserveLock('follow-up', meeting._id as string).then((lockId: string | undefined) => {
                    let accountName: string | undefined = undefined;
                    if (meeting.accountId) {
                        for (let account of this.props.accounts) {
                            if (account._id === meeting.accountId) {
                                accountName = account.name;
                            }
                        }
                    }

                    this.setState((state: IState) => {
                        return {
                            ...state,
                            open: false,
                            changed: false,
                            noteChanged: false,
                            chronoStarted: false,
                            initialDuration: 0,
                            patient,
                            professional: professional || undefined,
                            meeting,
                            accountName,
                            followUp: Object.assign({}, meeting?.followUp || this.getBlankFollowUp()),
                            contactRequest: undefined,
                            signature: null,
                            lockId: lockId || null,
                            lockError: !lockId,
                            lockLastPreserved: lockId ? new Date() : null
                        };
                    }, () => {
                        this.dialogRef.current?.openDialog();
                        this.loadContactRequest();
                        this.getSignature();
                        this.loadFollowUpHistory();
                        window.setTimeout(() => {
                            this._isMounted = true;
                            this.chronoTick();
                        }, 500);
                    });
                });
            });
        });
    }

    loadFollowUpHistory() {
        getFollowUpHistory(this.state.meeting?._id as string).then((history: FollowUpHistory[]) => {
            this.setState((state: IState) => {
                return {
                    ...state,
                    history
                };
            });
        });
    }

    loadContactRequest() {
        if (this.state.meeting?.contactRequestId) {
            getContactRequest(this.state.meeting?.contactRequestId).then((contactRequest: ContactRequest | null) => {
                if (contactRequest) {
                    this.setState((state: IState) => {
                        return {
                            ...state,
                            contactRequest
                        };
                    });
                }
            });
        }
    }

    closeDialogRequest(): boolean {
        if (this.state.changed) {
            this.confirmCloseDialogRef.current.openDialog();

            return false;
        } else {
            this.onClosed();
            return true;
        }
    }

    releaseCurrentLock(): Promise<void> {
        return new Promise<void>((resolve => {
            if (this.state.lockId) {
                releaseLock(this.state.lockId, 'follow-up', this.state.meeting?._id as string).then(() => {
                    this.setState((state: IState) => {
                        return {
                            lockId: null,
                            lockError: false,
                            lockLastPreserved: null
                        };
                    }, resolve);
                });
            } else {
                resolve();
            }
        }))
    }

    closeDialog() {
        this.onClosed();
        this.dialogRef.current?.closeDialog();
    }

    onClosed() {
        window.clearTimeout(this.chronoTimeout);
        this.props.setPreventClose(false);
        this.releaseCurrentLock().then(() => {
            this.resetState().then(() => {
                this._isMounted = false;
            });
        });
    }

    onActionButtonClick(key: string) {
        switch(key) {
            case 'save':
                this.save().then(() => {
                    this.props.setAppNotification({severity: 'success', message: 'Successfully saved'})
                });
                break;
            case 'print':
                this.reportFollowUpRef.current?.run();
                break;
            case 'request':
                this.reportContactRequestRef.current?.run();
                break;
            case 'medication':
                this.medicationDialogRef.current?.openDialog();
                break;
            case 'consultationReason':
                this.consultationReasonDialogRef.current?.openDialog();
                break;
        }
    }

    save(): Promise<void> {
        return new Promise(resolve => {
            if (this.validateFollowUp()) {
                saveFollowUp(this.state.meeting?._id as string, this.state.followUp).then(() => {
                    this.lastSave = new Date();
                    this.setState((state: IState) => {
                        return {
                            ...state,
                            changed: false,
                            noteChanged: false
                        };
                    }, () => {
                        this.props.onSave(this.state.followUp);
                        this.loadFollowUpHistory();
                        resolve();
                    });
                });
            }
        });
    }

    saveNoteHistory() {
        this.lastSave = new Date();
        saveFollowUpHistory(this.state.meeting?._id as string, this.state.followUp.notes || '').then((success: boolean) => {
            if (success) {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        noteChanged: false
                    };
                }, () => {
                    this.loadFollowUpHistory();
                });
            }
        });
    }

    validateFollowUp(): boolean {
        let formValidation: any = {};

        if (this.state.meeting?.status !== MEETING_STATUS.Missed) {
            if (this.state.followUp.completed && (!this.state.followUp.duration || this.state.followUp.duration <= 0)) {
                formValidation.duration = 'error';
            }
        }

        this.setState((state: IState) => {
            return {
                ...state,
                formValidation
            };
        });

        return Object.keys(formValidation).length === 0;
    }

    changeFollowUp(property: string, value: any) {
        if (!this._isMounted) return;
        this.setState((state: IState) => {
            let followUp: any = state.followUp;

            switch (property) {
                case 'duration':
                    value = dayjs(value).diff(dayjs().hour(0).minute(0).second(0).millisecond(0), 'second');
                    break;
                case 'completed':
                    followUp.completedOn = new Date();
                    break;
            }

            followUp[property] = value;
            return {
                ...state,
                followUp,
                changed: true,
                noteChanged: property === 'notes' || state.noteChanged || false
            };
        });
    }

    chronoTick() {
        this.chronoTimeout = window.setTimeout(() => {
            try {
                if (this.state.chronoStarted) {
                    this.setState((state: IState) => {
                        let followUp: any = Object.assign({}, state.followUp);
                        followUp.duration = (state.initialDuration || 0) + (dayjs().diff(dayjs(state.chronoStartedAt), 'second'));
                        return {
                            ...state,
                            followUp
                        };
                    });
                }

                if (dayjs().diff(this.lastSave, 'minute') >= 2 && this.state.noteChanged) {
                    this.saveNoteHistory();
                }

                if (this.state.lockId && dayjs().diff(this.state.lockLastPreserved, 'second') >= 10) {
                    preserveLock(this.state.lockId, 'follow-up', this.state.meeting?._id as string).then((success: boolean) => {
                        this.setState((state: IState) => {
                            return {
                                ...state,
                                lockId: success ? state.lockId : null,
                                lockError: !success,
                                lockLastPreserved: success ? new Date() : null
                            };
                        });
                    });
                }
            } catch(ex) {}
            this.chronoTick();
        }, 1000);
    }

    changeModel(noteModelId: string) {
        if (this.state.followUp.notes !== "") {
            this.selectingNoteModel = this.indexedNoteModels[noteModelId].note;
            this.confirmNoteModelDialogRef.current?.openDialog();
        } else {
            this.applyNoteModel(this.indexedNoteModels[noteModelId].note);
        }
    }

    applyNoteModel(noteModel: string | null) {
        this.changeFollowUp('notes', noteModel || this.selectingNoteModel);
    }

    getSignature() {
        getSignatureBase64(this.state.meeting?.professionalUserId || this.props.userId).then((signature: string | null) => {
            this.setState((state: IState) => {
                return {...state, signature};
            });
        });
    }

    refreshPatient(patient: Patient) {
        this.setState((state: IState) => {
            return {
                ...state,
                patient
            };
        }, () => {
            this.props.onPatientSave(patient);
        });
    }

    downloadDocument(category: string, name: string) {
        open(`/patient/download-document-template/${category}/${name}/${this.state.meeting?.patientId}`);
    }

    displayNoteHistory(history: FollowUpHistory) {
        if (history.note) {
            decryptNote(history.note).then((note: string) => {
                this.setState((state: IState) => {
                    return {
                        ...state,
                        currentNoteHistory: note
                    }
                }, () => {
                    this.confirmApplyNoteHistoryDialogRef.current?.openDialog();
                });
            });
        }
    }

    applyNoteHistory() {
        this.changeFollowUp('notes', this.state.currentNoteHistory);
    }

    getNoteModels() {
        getNoteModels().then((noteModels: NoteModel[]) => {
            this.indexedNoteModels = {};
            for (let noteModel of noteModels) {
                this.indexedNoteModels[noteModel._id as string] = noteModel;
            }
            this.setState((state: IState) => {
                return {
                    ...state,
                    noteModels
                };
            });
        });
    }

    requestNewNoteModelName() {
        this.setState((state: IState) => {
            return {...state, modelNameDialogOpen: true};
        });
    }

    closeNewNoteModel() {
        this.setState((state: IState) => {
            return {...state, modelNameDialogOpen: false};
        });
    }

    saveNewNoteModel() {
        if (this.state.newModelName.length > 0) {
            this.setState((state: IState) => {
                return {...state, modelNameDialogOpen: false};
            }, () => {
                saveNoteModel(new NoteModel({
                    name: this.state.newModelName,
                    note: this.state.followUp.notes
                })).then(() => {
                    this.getNoteModels();
                })
            });
        }
    }

    setNewNoteModelName(newModelName: string) {
        this.setState((state: IState) => {
            return {...state, newModelName};
        });
    }

    requestRemoveNoteModel(noteModelId: string) {
        this.removingNoteModelId = noteModelId;
        this.confirmRemoveNoteModelDialogRef.current?.openDialog();
    }

    removeNoteModel() {
        removeNoteModel(this.removingNoteModelId).then(() => {
            this.getNoteModels();
        });
    }

    render() {
        return (
            <FullScreenDialog
                ref={this.dialogRef}
                title={localize('Meeting follow up', this.props.lang)}
                onButtonClick={this.onActionButtonClick.bind(this)}
                onCloseRequest={this.closeDialogRequest.bind(this)}
                buttons={[
                    {key: 'medication', text: localize('Medication', this.props.lang), color: 'info', keepDialogOpen: true},
                    {key: 'consultationReason', text: localize('Consultation reason', this.props.lang), color: 'info', keepDialogOpen: true},
                    {key: 'documents', text: localize('Documents', this.props.lang), color: 'secondary', keepDialogOpen: true, disabled: !this.state.accountName || (['IVAC', 'CNESST']).indexOf(this.state.accountName) === -1,
                        menu: (() => {
                            switch(this.state.accountName) {
                                case 'IVAC':
                                    return [
                                        {text: localize('Evaluation', this.props.lang), onClick: this.downloadDocument.bind(this, 'IVAC', 'EVALUATION')},
                                        {text: localize('Evolution', this.props.lang), onClick: this.downloadDocument.bind(this, 'IVAC', 'EVOLUTION')},
                                        {text: localize('Final', this.props.lang), onClick: this.downloadDocument.bind(this, 'IVAC', 'FINAL')}
                                    ];

                                case 'CNESST':
                                    return [
                                        {text: localize('Evaluation', this.props.lang), onClick: this.downloadDocument.bind(this, 'CNESST', 'EVALUATION')},
                                        {text: localize('Evolution', this.props.lang), onClick: this.downloadDocument.bind(this, 'CNESST', 'EVOLUTION')},
                                        {text: localize('Final', this.props.lang), onClick: this.downloadDocument.bind(this, 'CNESST', 'FINAL')}
                                    ];

                                default:
                                    return [];
                            }
                        })()
                    },
                    {key: 'request', text: localize('Request', this.props.lang), color: 'info', keepDialogOpen: true, disabled: !this.state.contactRequest},
                    {key: 'print', text: localize('Print', this.props.lang), color: 'info', keepDialogOpen: true, disabled: !this.state.followUp.completed || this.state.changed},
                    {key: 'save', text: localize('Save', this.props.lang), color: 'success', keepDialogOpen: true, disabled: !this.state.changed}
                ]}
            >
                <Box sx={{p:2, height: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
                    <Box>
                        <Box sx={{display: 'flex'}}>
                            <Grid container>
                                <Grid item xs={12} sx={{p:2}}>
                                    {
                                        this.state.lockError ?
                                            <Alert
                                                severity={'warning'}
                                                sx={{ width: '100%' }}
                                                variant={'filled'}
                                            >
                                                <Text>Concurrency error</Text>
                                                <br/>
                                                <Text>Another screen appears to be opened for this follow-up. Please be sure to edit this follow-up in one place only.</Text>
                                            </Alert>
                                            :
                                            null
                                    }
                                </Grid>
                                <Grid item xs={12} md={6} sx={{p:2}}>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Box sx={{pr: 2}}>
                                            <TimePicker
                                                sx={{width: '100%'}}
                                                label={localize('Meeting duration', this.props.lang)}
                                                value={dayjsOrAny(dayjs().hour(0).minute(0).second(0).millisecond(0).add(this.state.followUp.duration as number, 'second'))}
                                                onChange={(newValue: any) => this.changeFollowUp('duration', newValue)}
                                                ampm={false}
                                                minutesStep={5}
                                                readOnly={this.state.chronoStarted || this.state.followUp.completed}
                                                format={'HH:mm:ss'}
                                                minTime={dayjs(`${dayjs().format('YYYY-MM-DD')}T00:01`)}
                                                disabled={this.state.meeting?.status === MEETING_STATUS.Missed}
                                            />
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} md={3} sx={{p:2}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={
                                                    ((this.state.meeting?.status !== MEETING_STATUS.Missed
                                                    && (!this.state.followUp.duration || this.state.followUp.duration <= 0))
                                                    || (this.state.followUp.completed && !this.props.canUncompleteFollowUp))
                                                }
                                                checked={this.state.followUp.completed}
                                                onChange={(e) => this.changeFollowUp('completed', e.target.checked)} />
                                        }
                                        label={localize('Follow up completed', this.props.lang)}
                                    />
                                </Grid>
                            </Grid>
                            <ReportViewer ref={this.reportFollowUpRef} title={"Report follow up"}>
                                <FollowUpReport
                                    patient={this.state.patient as Patient}
                                    professional={this.state.professional as User}
                                    meetingDate={this.state.meeting?.timeFrom as Date}
                                    meetingTime={dayjs().hour(0).minute(0).second(0).millisecond(0).add(this.state.followUp.duration as number, 'second').toDate()}
                                    notes={this.state.followUp.notes as string}
                                    lang={this.props.lang}
                                    dateFormat={this.props.dateFormat}
                                    signature={this.state.signature || undefined}
                                    completedOn={this.state.followUp.completedOn || undefined}
                                />
                            </ReportViewer>
                            <ReportViewer ref={this.reportContactRequestRef} title={""}>
                                <ContactRequestReport
                                    contactRequest={this.state.contactRequest}
                                    accounts={this.props.accounts}
                                    lang={this.props.lang}
                                    dateFormat={this.props.dateFormat}
                                />
                            </ReportViewer>
                        </Box>
                    </Box>
                    <Box sx={{flexGrow: 1, display: 'flex', overflow: 'auto'}}>
                        <Box sx={{height: '100%', width: '100%', display: 'flex'}}>
                            <Box sx={{p:2, display: 'flex', flexDirection: 'column'}}>
                                <Box>
                                    <Text>Note history</Text>
                                </Box>
                                <Box sx={{mt: 2, mb: 1, flexGrow: 1, bgcolor: 'background.paper', overflow: 'auto'}}>
                                    {
                                        this.state.history.length > 0 ?
                                            <List>
                                                {
                                                    this.state.history.map((history: FollowUpHistory) => {
                                                        return (
                                                            <ListItem key={history._id}>
                                                                <ListItemButton onClick={this.displayNoteHistory.bind(this, history)}>
                                                                    <ListItemText primary={dayjs(history.date).format('YYYY-MM-DD HH:mm:ss')} />
                                                                </ListItemButton>
                                                            </ListItem>
                                                        );
                                                    })
                                                }
                                            </List>
                                            :
                                            <List>
                                                <ListItem>
                                                    <ListItemButton>
                                                        <ListItemText primary={localize('No available history', this.props.lang)} />
                                                    </ListItemButton>
                                                </ListItem>
                                            </List>
                                    }
                                </Box>
                            </Box>
                            <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                                <Box sx={{p:2, display: 'flex'}}>
                                    <Box sx={{flexGrow: 1}}>
                                        <TextField
                                            id="noteModel"
                                            sx={{width: '100%'}}
                                            select
                                            value={''}
                                            label={localize('Note model', this.props.lang)}
                                            onChange={((e: any) => {this.changeModel(e.target.value);})}
                                        >
                                            {this.state.noteModels.map((noteModel: NoteModel) => (
                                                <MenuItem key={noteModel._id} value={noteModel._id}>
                                                    <Box sx={{display: 'flex', width: '100%', alignItems: 'center'}}>
                                                        <Box sx={{flexGrow: 1}}>
                                                            {noteModel.name}
                                                        </Box>
                                                        <Box>
                                                            <IconButton
                                                                disabled={noteModel.userId !== this.props.userId}
                                                                onClick={(event) => {event.stopPropagation();this.requestRemoveNoteModel(noteModel._id as string);}}>
                                                                <ClearIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Box>
                                    <Box sx={{pl: 2}}>
                                        <Button
                                            color={'primary'}
                                            variant={'contained'}
                                            size={'large'}
                                            onClick={this.requestNewNoteModelName.bind(this)}
                                            disabled={!this.state.followUp || !this.state.followUp.notes || this.state.followUp.notes.length === 0}
                                        >
                                            <Text>Save note model</Text>
                                        </Button>
                                    </Box>
                                </Box>
                                <Box sx={{flexGrow: 1, m:2, backgroundColor: 'white', overflow: 'auto'}}>
                                    <ReactQuill
                                        theme="snow"
                                        value={this.state.followUp.notes}
                                        readOnly={this.state.followUp.completed}
                                        onChange={(newValue: string) => {this.changeFollowUp('notes', newValue);}}
                                        style={{backgroundColor: 'white', height: '90%', color: 'black'}}/>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
                <ConfirmDialog
                    ref={this.confirmNoteModelDialogRef}
                    title={localize('Apply note model?', this.props.lang)}
                    text={localize('You will lost any entered note. Do you want to continue?', this.props.lang)}
                    onAgree={this.applyNoteModel.bind(this, null)}
                />
                <ConfirmDialog
                    ref={this.confirmCloseDialogRef}
                    title={localize('Unsaved changes', this.props.lang)}
                    text={localize('All unsaved changes will be lost. Do you want to continue?', this.props.lang)}
                    onAgree={this.closeDialog.bind(this)}
                />
                <ConfirmDialog
                    ref={this.confirmApplyNoteHistoryDialogRef}
                    title={localize('Apply note history?', this.props.lang)}
                    html={this.state.currentNoteHistory}
                    onAgree={this.applyNoteHistory.bind(this)}
                    canAgree={!this.state.followUp.completed}
                />
                <AdditionalInfoDialog
                    ref={this.medicationDialogRef}
                    patient={this.state.patient as Patient}
                    onSave={this.refreshPatient.bind(this)}
                    infoLabel={localize('Medication', this.props.lang)}
                    infoAttr={'medication'}
                    saveChanges={true}
                />
                <ConsultationReasonsDialog
                    ref={this.consultationReasonDialogRef}
                    patient={this.state.patient as Patient}
                    onSave={this.refreshPatient.bind(this)}
                    saveChanges={true}
                />
                <Dialog open={this.state.modelNameDialogOpen}>
                    <DialogTitle><Text>New model name</Text></DialogTitle>
                    <DialogContent>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Box sx={{p:2}}>
                                <TextField
                                    sx={{width: '100%'}}
                                    label={localize('Model name', this.props.lang)}
                                    onChange={(e) => {this.setNewNoteModelName(e.target.value)}}
                                    defaultValue={''}
                                />
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button color={'error'} onClick={() => {this.closeNewNoteModel()}} ><Text>Cancel</Text></Button>
                        <Button color={'success'} disabled={this.state.newModelName.length === 0} onClick={() => {this.saveNewNoteModel()}} ><Text>Save</Text></Button>
                    </DialogActions>
                </Dialog>
                <ConfirmDialog
                    ref={this.confirmRemoveNoteModelDialogRef}
                    title={localize('Remove note model', this.props.lang)}
                    text={localize('Are you sure you want to remove this note model?', this.props.lang)}
                    onAgree={this.removeNoteModel.bind(this)}
                />
            </FullScreenDialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(FollowUpMeetingDialog);
