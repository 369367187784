import dayjs from "dayjs";
import {Patient} from "./patient";
import FollowUp from "./followUp";
import {ORGANIZATION_LINK} from "../constants/enums";

export enum MEETING_STATUS {
    Created = 0,
    Confirmed = 1,
    Completed = 2,
    Reported = 3,
    Canceled = 4,
    Missed = 5,
    CanceledByPatient = 6
}

export enum COMMUNCATION_CHANNEL {
    Person = 0,
    Phone = 1,
    Visio = 2
}

export enum PATIENT_ANSWER {
    Canceled = 0,
    Confirmed = 1
}

export default class Meeting {
    public _id?: string = undefined;
    public createdByUserId?: string = undefined;
    public professionalUserId?: string = undefined;
    public patientId?: string = undefined;
    public accountId?: string = undefined;
    public organizationLink?: ORGANIZATION_LINK = undefined;
    public contractCode?: string = undefined;
    public onContractExtension?: boolean = undefined;
    public contactRequestId?: string = undefined;
    public createdDate?: Date = undefined;
    public canceledDate?: Date = undefined;
    public date?: Date = undefined;
    public timeFrom?: Date = undefined;
    public timeTo?: Date = undefined;
    public arrivedAt?: Date = undefined;
    public note?: string = undefined;
    public link?: string = undefined;
    public status?: MEETING_STATUS = undefined;
    public followUp?: FollowUp = undefined;
    public paidByPatient?: boolean = undefined;
    public isBlock?: boolean = undefined;
    public isPaid?: boolean = undefined;
    public paidAmount?: number = undefined;
    public communicationChannel?: COMMUNCATION_CHANNEL = undefined;
    public placeId?: string = undefined;
    public icsUID?: string = undefined;
    public icsSequence?: number = undefined;
    public patientAnswer?: PATIENT_ANSWER = undefined;
    public patientAnswerReceivedOn?: Date = undefined;
    public welcomeCode?: number = undefined;

    public constructor(init?:Partial<Meeting>) {
        if (init && init.createdDate) init.createdDate = dayjs(init.createdDate).isValid() ? dayjs(init.createdDate).toDate() : undefined;
        if (init && init.canceledDate) init.canceledDate = dayjs(init.canceledDate).isValid() ? dayjs(init.canceledDate).toDate() : undefined;
        if (init && init.date) init.date = dayjs(init.date).isValid() ? dayjs(init.date).toDate() : undefined;
        if (init && init.timeFrom) init.timeFrom = dayjs(init.timeFrom).isValid() ? dayjs(init.timeFrom).toDate() : undefined;
        if (init && init.timeTo) init.timeTo = dayjs(init.timeTo).isValid() ? dayjs(init.timeTo).toDate() : undefined;
        Object.assign(this, init);
    }
}

export class MeetingWithPatient extends Meeting {
    public patient?: Patient = undefined

    public constructor(init?:Partial<MeetingWithPatient>) {
        super(init);
        if (init?.patient) this.patient = init?.patient;
    }
}
