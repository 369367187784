import {AxiosResponse} from "axios";
import {post, upload} from "./ajax";
import {Contact} from "../models/contact";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export async function executeJSReport(templateName: string, data: any, reportName: string, fileType: string): Promise<any> {
    let response: AxiosResponse<any> = await post<any>(`/reports/execute/${templateName}`,
        {data},
        {
            responseType: 'arraybuffer'
        });


    if (response.status === 200 && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${reportName}.${(fileType === 'excel' ? 'xlsx' : 'pdf')}`);
        document.body.appendChild(link);
        link.click();

        return response.data;
    } else {
        return null;
    }
}

export async function sendReport(reportData: string, contacts: Array<Contact | string>, subject: string, body: string): Promise<boolean> {
    const pdfBlob = await generatePDF(reportData);
    const formData = new FormData();
    formData.append("fileupload", pdfBlob);
    formData.append("contacts", JSON.stringify(contacts));
    formData.append("subject", subject);
    formData.append("body", body);
    let response: AxiosResponse<any> = await upload<any>(`/reports/send`,
        formData
    );

    return response.status === 200;
}

export async function generatePDF(reportHTML: string) {
    // Convertir la chaîne HTML en un élément DOM
    const tempElement = document.createElement("div");
    tempElement.style.width = '1080px';
    tempElement.innerHTML = reportHTML;
    document.body.appendChild(tempElement);

    // Convertir en canvas
    const canvas = await html2canvas(tempElement);
    const imgData = canvas.toDataURL("image/png");

    // Générer le PDF
    const pdf = new jsPDF("p", "mm", "a4");
    pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
    const pdfBlob = pdf.output("blob");

    // Supprimer l'élément temporaire
    document.body.removeChild(tempElement);

    return pdfBlob;
}
